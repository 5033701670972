import React from "react"
import { Link } from "gatsby"

import headerImage from '../images/ecommerce/magento-demos@2x.png';

import './css/aplicativos.scss'
import SEO from "../components/seo"
import Phone from "../images/aplicativos/tablet-phone-.png";
import LogoApp from "../images/aplicativos/stores.png";
import Laptop from "../images/aplicativos/laptop-mockup-11.png";
import Totvs from "../images/aplicativos/Cloud/logo-totvs-2.png";
import Magento from "../images/aplicativos/Cloud/magento-cirle.png";
import SAP from "../images/aplicativos/Cloud/sap.png";
import Cielo from "../images/aplicativos/Cloud/cielo.png";
import ApplePay from "../images/aplicativos/Cloud/apple-pay.png";
import GooglePay from "../images/aplicativos/Cloud/google-pay.png";
import SE from "../images/aplicativos/Cloud/software-express.png";
import Visa from "../images/aplicativos/Cloud/visa-checkout.png";
import Master from "../images/aplicativos/Cloud/masterpass-circle.png";
import Bitrix from "../images/aplicativos/Cloud/bitrix.png";
import Stone from "../images/aplicativos/Cloud/stone-pagamentos.png";
import MP from "../images/aplicativos/Cloud/mercado-pago.png";
import WP from "../images/aplicativos/Cloud/wordpress.png";
import PagSeguro from "../images/aplicativos/Cloud/pag-seguro.png";
import Drupal from "../images/aplicativos/Cloud/drupal.png";
import Rede from "../images/aplicativos/Cloud/rede-logo.png";

import Layout from "../components/layout";
import logoMagento from "../images/ecommerce/magento_logo.svg";

const Aplicativos =() => (
    <Layout>
        <SEO title="Aplicativos" description="Desenvolvemos soluções mobile para o seu negócio voltado para o que realmente ele precisa." keywords={[`aplicativos`, `aplicativos mobile`, `aplicativos android`, `aplicativos ios`, `react native`, `desenvolvimento android`, `desenvolvimento ios`, `desenvolvimento aplicativos`, `desenvolvimento app`]} />
        <div className="main" role="main">
            <div className="ui-hero hero-lg hero-center ui-gradient-blue ui-waves hero-svg-layer-3">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12" data-vertical_center="true" data-vertical_offset="16">
                            <h1 className="heading animate" data-show="fade-in-up-big" data-delay="100">
                                Saia na frente, Mobilize-se
                            </h1>
                            <p className="paragraph">Desenvolvemos soluções mobile para o seu negócio voltado para o que realmente ele precisa.</p>
                        </div>
                        {/* <div className="col-sm-12 animate" data-show="fade-in-up" data-delay="400">
                            <img src={Phone}
                                 alt="Applify - App Landing Page HTML Template"
                                 data-uhd data-max_width="1000" className="responsive-on-lg"/>
                        </div> */}
                    </div>
                </div>
            </div>


            <div id="features" className="section">
                <div className="container ui-icon-blocks ui-blocks-h icons-lg">
                    <div className="section-heading center">
                        <h2 className="heading text-indigo">
                            Principais Recursos
                        </h2>
                        <p>
                        Recursos feitos sob medida que farão você sair na frente.
                    </p>

                    </div>

                    <div className="row">
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon icon-lg icon-circle icon-credit-card ui-gradient-dark"></div>
                            <h4>Pagamentos</h4>
                            <p>
                                Gateways de pagamento que auxiliarão o seu negócio com segurança e tranquilidade.
                            </p>
                        </div>
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon icon-lg icon-circle icon-settings ui-gradient-blue"></div>
                            <h4>Integração</h4>
                            <p>
                                Seu aplicativo mobile lado a lado com o sistema integrado de sua empresa.
                            </p>
                        </div>
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon icon-lg icon-circle icon-map ui-gradient-peach"></div>
                            <h4>Geolocalização</h4>
                            <p>
                                Serviços que te auxiliam a identificar, localizar e rastrear clientes, colaboradores
                                e mercadorias.
                            </p>
                        </div>
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon icon-lg icon-circle fa fa-users ui-gradient-peach"></div>
                            <h4>UX/UI Design</h4>
                            <p>
                                Focamos em desenvolver soluções para que o usuário tenha a melhor experiência.

                            </p>
                        </div>
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon icon-lg icon-circle icon-camera ui-gradient-green"></div>
                            <h4>Recursos de Câmera</h4>
                            <p>
                                Leitura de QrCode, reconhecimento facial e de produtos e muito mais.
                            </p>
                        </div>
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon icon-lg icon-circle fa fa-database ui-gradient-dark"></div>
                            <h4>Recursos Off-line</h4>
                            <p>
                                Sincronismo de dados para utilização do App em locais de baixa conexão a internet.
                            </p>
                        </div>
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon icon-lg icon-circle fa fa-thumbs-o-up ui-gradient-blue"></div>
                            <h4>Login em Mídias Sociais</h4>
                            <p>
                                Cadastro de usuários simples e fácil com login em redes sociais.
                            </p>
                        </div>
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon icon-lg icon-circle fa fa-line-chart ui-gradient-dark"></div>
                            <h4>Consumo de API</h4>
                            <p>
                                Consulte, altere e cadastre informações em tempo real.
                            </p>
                        </div>
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon icon-lg icon-circle fa fa-envelope-o ui-gradient-peach"></div>
                            <h4>Push Notifications</h4>
                            <p>
                                Notificações personalizadas para sempre manter o contato com os usuários da sua aplicação.
                            </p>
                        </div>

                    </div>
                </div>
            </div>

            <div id="integrate" className="section ui-gradient-blue">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-5 col-xl-6" data-vertical_center="true">

                            <div className="section-heading mb-2">
                                <h2 className="heading">
                                    Seu App em Boas Mãos
                                </h2>
                                <p className="paragraph">
                                  A <strong>System Code</strong> conta com integração das maiores gateways de pagamentos e sistemas para sua empresa.
                                </p>
                            </div>

                            <ul className="ui-icon-blocks ui-blocks-v icons-sm">
                                <li className="ui-icon-block">
                                    <span className="icon fa fa-code"></span>
                                    <p className="">
                                        Possuímos o know-how em diversas tecnologias para o desenvolver o seu projeto com o melhor custo benefício
                                        e em um menor tempo.
                                    </p>
                                </li>
                                <li className="ui-icon-block">
                                    <span className="icon fa fa-sitemap"></span>
                                    <p className="">
                                        Se sua ideia é criar um aplicativo institucional, sem integração ou com integração como a meios de pagamentos,
                                        carteiras digitais, sistemas ERPs, CRMs, Lojas Virtuais ou até mesmo desenvolvimentos de MVPs.
                                    </p>
                                </li>
                                <li className="ui-icon-block">
                                    <span className="icon fa fa-lightbulb-o"></span>
                                    <p className="">
                                      A <strong>System Code</strong> estará preparada para tirar seu projeto do papel, analisando a sua ideia e suas necessidades.
                                    </p>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-7 col-xl-6">

                            <div className="ui-logos-cloud">
                                <div data-size="4" className="mt-0 animate" data-show="fade-in">
                                    <img src={Stone} data-uhd alt="Applif App Landing Page"/>
                                </div>
                                <div data-size="10" className="mt-0 animate" data-show="fade-in">
                                    <img src={GooglePay} data-uhd alt="Applif App Landing Page"/>
                                </div>
                                <div data-size="7" className="mt-0 animate" data-show="fade-in">
                                    <img src={ApplePay} data-uhd alt="Applif App Landing Page"/>
                                </div>

                                <span className="flex-break"></span>

                                <div data-size="4" className="animate" data-show="fade-in">
                                    <img src={Bitrix} data-uhd alt="Applif App Landing Page"/>
                                </div>
                                <div data-size="10" className="animate" data-show="fade-in">
                                    <img src={Totvs} data-uhd alt="Applif App Landing Page"/>
                                </div>
                                <div data-size="4" className="animate" data-show="fade-in">
                                    <img src={MP} data-uhd alt="Applif App Landing Page"/>
                                </div>
                                <div data-size="6" className="animate" data-show="fade-in">
                                    <img src={Visa} data-uhd alt="Applif App Landing Page"/>
                                </div>
                                <div data-size="3" className="animate" data-show="fade-in">
                                    <img src={WP} data-uhd alt="Applif App Landing Page"/>
                                </div>

                                <span className="flex-break"></span>

                                <div data-size="8" className="animate" data-show="fade-in">
                                    <img src={SE} data-uhd alt="Applif App Landing Page"/>
                                </div>
                                <div data-size="3" className="animate" data-show="fade-in">
                                    <img src={Drupal} data-uhd alt="Applif App Landing Page"/>
                                </div>
                                <div data-size="10" className="animate" data-show="fade-in">
                                    <img src={Magento} data-uhd alt="Applif App Landing Page"/>
                                </div>
                                <div data-size="5" className="animate" data-show="fade-in">
                                    <img src={Cielo} data-uhd alt="Applif App Landing Page"/>
                                </div>

                                <span className="flex-break"></span>

                                <div data-size="3" className="animate" data-show="fade-in">
                                    <img src={Rede} data-uhd alt="Applif App Landing Page"/>
                                </div>
                                <div data-size="10" className="animate" data-show="fade-in">
                                    <img src={SAP} data-uhd alt="Applif App Landing Page"/>
                                </div>
                                <div data-size="7" className="animate" data-show="fade-in">
                                    <img src={Master} data-uhd alt="Applif App Landing Page"/>
                                </div>

                                <span className="flex-break"></span>

                                <div data-size="4" className="mb-0 animate" data-show="fade-in">
                                    <img src={PagSeguro} data-uhd alt="Applif App Landing Page"/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            {/* <div className="section laptop-showcase bg-white" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-lg-4" data-vertical_center="true">
                            <div>
                                <h2 className="heading text-dark-gray">
                                    Do Tamanho Que Você Precisa
                                </h2>
                                <p>
                                    <p>Tenha o controle do seu aplicativo com uma dashboard customizada.
                                      Além do aplicativo, desenvolvemos a solução completa para o gerenciamento do seu aplicativo.</p>
                                    <Link to="aplicacoes" target="_blank" className="dark-btn-link btn-arrow">Saiba Mais</Link>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-8">
                            <img className="responsive-on-sm laptop" src={Laptop} data-uhd
                                 alt="Applify - App Landing HTML Template" data-max_width="1000"/>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="section ui-gradient-dark ui-action-section ui-tilt">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-7 text-block" data-vertical_center="true">
                            <div className="section-heading">
                                <h2 className="heading-app-text">
                                    A Disponibilidade que Você Precisa
                                </h2>
                                <p>
                                    A <strong>System Code</strong> trabalha com desenvolvimento de aplicativos nativos e híbridos.
                                    Tudo feito para você ter a melhor performance e experiência.

                                </p>
                            </div>
                        </div>
                        <div className="logo-magento col-md-4 col-sm-5 img-block animate" data-show="fade-in-left">
                            <img src={LogoApp}
                                 data-uhd className="responsive-on-sm" data-max_width="450"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
);

export default Aplicativos
